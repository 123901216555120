import { computed, ref } from '@vue/composition-api'

const NO_TRANSACTION_TYPE_NAME_FILTER = ''

const transactionTypesSearchQueryState = ref(NO_TRANSACTION_TYPE_NAME_FILTER)

export const changeTransactionTypesSearchQuery = searchQuery => {
	transactionTypesSearchQueryState.value = searchQuery
}

export const transactionTypesSearchQuery = computed({
	get () {
		return transactionTypesSearchQueryState.value
	},
	set (value) {
		changeTransactionTypesSearchQuery(value)
	}
})

export const resetTransactionTypesFilters = () => {
	changeTransactionTypesSearchQuery(NO_TRANSACTION_TYPE_NAME_FILTER)
}
