<template>
	<ViewLayout>
		<template #header-title>
			Transaction Types
		</template>
		<template #header-caption>
			{{ 'Create, edit and manage Cassie Transaction Types' | useLabels }}
		</template>
		<template #header-after>
			<slot name="header-after" />
		</template>
		<template #content>
			<slot
				name="content"
			/>
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout }
}
</script>
